module.exports = {
  keyShort: 'de',
  key: 'de-DE',
  general: {
    back: 'Zurück',
    root: 'Hauptverzeichnis',
    pleaseChoose: 'Bitte wählen',
    ok: 'OK',
    cancel: 'Abbrechen',
    copy: 'Kopie',
    empty: 'Keine Einträge gefunden!',
    uploading: 'Hochladen...',
    refresh: 'Neu laden',
    inactive: 'Inaktiv',
    templates: 'Vorlagen',
    month: 'Monat',
    year: 'Jahr',
    orderNow: 'Jetzt bestellen!',
    row: 'Zeile',
  },
  login: {
    btnLogIn: 'Anmelden',
    onboarding: 'Onboarding',
    createFree: 'Neuer Mandant...',
    terms: 'Nutzungsbedingungen',
    privacy: 'Datenschutz',
    imprint: 'Impressum',
    startForFree: 'Kostenlos starten!',
    showLogin: 'Zur Anmeldung',
    introTitle: 'Willkommen zu Collada!',
    introText: 'Sind Sie neu bei Collada? Dann können Sie hier gleich mit einem kostenlosen Mandanten loslegen!',
    introText2: 'Oder haben Sie bereits einen Collada-Mandanten? Dann öffnen Sie einfach die Anmeldung!',
  },
  settings: {
    daymessages: 'Ankündigungen',
    users: 'Benutzer',
    usergroups: 'Benutzergruppen',
    devices: 'Geräte',
    references: 'Referenzen',
    invoicing: 'Abonnement',
    mailsettings: 'Mail-Server',
  },
  qrcode: {
    download: 'QR Code als Bild herunterladen',
    copy: 'Verbindungsinformationen in Zwischenablage kopieren',
    lblTenant: 'Ihre Mandanten-ID:',
    lblConnInfo: 'Ihre Verbindungsinformationen (entspricht der Information im QR Code)',
    lblHint: 'Wichtig:',
    lblStoreSafelyHint: 'Stellen Sie unbedingt sicher, dass Sie Ihre Verbindungsinformation und / oder '
    + 'Ihren QR Code an einem sicheren Ort aufbewahren! Ihr persönlicher Schlüssel, den Sie benötigen, um '
    + 'Ihre Daten zu ver- und entschlüsseln ist darin enthalten. Ohne diesen Schlüssel können Sie nicht mehr '
    + 'auf Ihre Daten zugreifen! Und wir (das Collada-Team) haben keine Möglichkeit, den Schlüssel für Sie '
    + 'wiederherzustellen! Der Schlüssel wird zu keiner Zeit auf unseren System gespeichert!',
    btnCreateMagicLink: 'Eigenen Magic Link erzeugen',
  },
  pageTitle: {
    dayMessages: 'Ankündigungen',
    forms: 'Formulare',
    login: 'Anmelden',
    reports: 'Berichte',
    explorer: 'Dateien',
    settings: 'Einstellungen',
    users: 'Benutzer',
    usergroups: 'Benutzergruppen',
    reportDetail: 'Berichtdetails',
    devices: 'Geräte',
    contactBook: 'Kontakte',
    references: 'Referenzen',
    qrcode: 'QR Code',
    legal: 'Rechtliches',
    invoicing: 'Abonnement',
  },
  dayShortNames: {
    mo: 'Mo',
    tu: 'Di',
    we: 'Mi',
    th: 'Do',
    fr: 'Fr',
    sa: 'Sa',
    su: 'So',
  },
  modal: {
    titleEditFolder: 'Verzeichnis bearbeiten',
    titleCreateFolder: 'Verzeichnis erstellen',
    labelName: 'Name',
    labelInherits: 'Erbt die Rechte',
    labelRights: 'Rechte',
    labelUserGroup: 'Benutzergruppe',
    labelRead: 'Lesen',
    labelWrite: 'Schreiben',
    labelCollectData: 'Daten erfassen',
    labelEditConfig: 'Konfigurieren',
    labelAddUserGroup: 'Benutzergruppe hinzufügen',
    titleEditForm: 'Formular bearbeiten',
    titleCreateForm: 'Formular erstellen',
    labelUserOrGroup: 'Benutzer / Gruppe',
    labelFirstname: 'Vorname',
    labelLastname: 'Nachname',
    labelEmail: 'E-Mail',
    labelUsername: 'Benutzername',
    labelEmailNew: 'Neue E-Mail-Adresse',
    labelPassword: 'Passwort',
    labelPasswordConfirmation: 'Passwort bestätigen',
    labelCaptcha: 'Code eingeben',
    lblAcceptTerms: '',
    txtAcceptTerms: 'Hiermit bestätige ich, die <a href="/legal/privacy" target="_legal">Datenschutzerklärung</a> '
      + 'und <a href="/legal/terms" target="_legal">Nutzungsbedingungen</a> der Collada Plattform gelesen und akzeptiert zu haben.',
    labelIsAdmin: 'Ist Admin',
    labelProductPermissions: 'Berechtigungen',
    labelUsergroups: 'Benutzergruppen',
    labelUsers: 'Benutzer',
    labelUserName: 'Benutzername',
    titleEditUserGroup: 'Benutzergruppe bearbeiten',
    titleCreateUserGroup: 'Benutzergruppe erstellen',
    labelGroupName: 'Gruppenname...',
    titleEditUser: 'Benutzer bearbeiten',
    titleCreateUser: 'Benutzer erstellen',
    labelExportCVS: 'CSV Exportieren',
    labelStartDate: 'Startdatum',
    labelEndDate: 'Enddatum',
    labelFieldSeparator: 'Feld-Trennzeichen',
    labelLineSeperator: 'Zeilenumbruch',
    hintLineSeperator: '<NEWLINE> für Enter / Return',
    labelFieldValueStartEnd: 'Wert Anfangs- / Endmarkierung',
    labelInCellListSeparator: 'Listen-Trennzeichen in einer Zelle',
    hintInCellListSeparator: '<SPACE> for sapce',
    labelOptions: 'Optionen',
    titleDelete: 'Löschen bestätigen',
    titleDeactivate: 'Deaktivierung bestätigen',
    textDeactivate: 'Wollen Sie das Formular wirklich deaktivieren? Die Benutzer können das Formular nicht mehr herunterladen und keine Daten mehr sammeln. Das Formular wird als inaktiv markiert und kann wieder reaktiviert werden. Fortfahren?',
    textDelete: 'Wollen Sie diesen Eintrag wirklich löschen?',
    textDeleteConfig: 'Wollen Sie das Formular endgültig löschen? Alle zugehörigen Berichte auf dem Server und auf den Endgeräten der Benutzer werden damit ebenfalls gelöscht! Fortfahren?',
    titleRenameFile: 'Datei umbenennen',
    textRenameFile: 'Bitte geben Sie den neuen Dateinamen ein:',
    titleUserGroupName: 'Benutzergruppe',
    textUserGroupName: 'Bitte geben Sie den Namen der Benutzergruppe ein',
    titlePageName: 'Seite',
    textPageName: 'Bitte geben Sie den Namen der Seite ein:',
    titleDropDownValueName: 'Aufzählungsliste',
    textDropDownValueName: 'Bitte geben Sie den Wert für die Aufzählungsliste ein:',
    titleReportConfigName: 'Formularname',
    textReportConfigName: 'Bitte geben Sie den Namen des Formulars ein:',
    titleEditContactGroup: 'Kontaktgruppe bearbeiten',
    titleCreateContactGroup: 'Kontaktgruppe erstellen',
    titleEditContact: 'Kontakt bearbeiten',
    titleCreateContact: 'Kontakt erstellen',
    titleContact: 'Kontakt',
    labelPhone: 'Telefon',
    labelMobile: 'Handy',
    labelFax: 'Fax',
    labelStreet: 'Straße',
    labelStreet_2: 'Straße (ergänzend)',
    labelZipCode: 'PLZ',
    labelCity: 'Ort',
    labelDepartment: 'Abteilung',
    labelPosition: 'Position',
    labelTitle: 'Titel',
    labelRoom: 'Raum',
    labelCompany: 'Unternehmen',
    labelCountry: 'Land',
    titleEditDayMessage: 'Ankündigung bearbeiten',
    titleCreateDayMessage: 'Ankündigung erstellen',
    labelDescription: 'Beschreibung',
    labelShouldInvalidates: 'Soll ablaufen',
    labelInvalidateDateTime: 'Ablaufdatum',
    labelShowOnWeb: 'Im Browser anzeigen',
    labelShowOnColada: 'In Collada App anzeigen',
    labelDayMessage: 'Ankündigung',
    titleNewTenant: 'Neuer Mandanten',
    textNewTenant: 'Wie soll der neue Mandant heißen?',
    titleAdminPassword: 'Administrator-Passwort',
    textAdminPassword: 'Was soll das Passwort des Administrators sein (Nutzername: admin)?',
    titleMagicLink: 'Magic link',
    textMagicLink: 'Bitte geben Sie das Passwort zu diesem Magic Link ein',
    exportLanguage: 'Export Sprache',
    exportFilter: 'Export Filter',
    textTemplateDesc: 'Bitte eine Beschreibung für die neue Vorlage eingeben.',
    titleTemplateDesc: 'Neue Vorlage',
    titleFreeTenant: 'Kostenlosen Mandanten erstellen',
    labelAdminPassword: 'Administrator-Passwort',
    labelTenantName: 'Mandantenname',
    labelAdminName: 'Nutzername des Admins',
    titleEditReference: 'Referenz bearbeiten',
    titleCreateReference: 'Referenz erstellen',
    labelContact: 'Kontakt',
    titleDeleteTenant: 'Mandanten löschen',
    textDeleteTenant: 'Möchten Sie wirklich den kompletten Mandanten, inklusive aller Daten löschen? Diese Aktion kann NICHT rückgängig gemacht werden! Alle Daten werden vom Server entfernt!',
    textConfirmTenantNameForDeletion: 'Bitte geben Sie die Mandanten ID <b>§1</b> zur Bestätigung des Löschvorgangs ein.',
    titleMagicLinkPassword: 'Passwort für den Link',
    textMagicLinkPassword: 'Bitte geben Sie ein Passwort ein, um den Link zu schützen, oder bestätigen und merken Sie sich das vorgeschlagene.',
    taxId: 'USt-ID',
    titleLeaveEditor: 'Designer verlassen',
    textLeaveEditor: 'Haben Sie alle Änderungen gespeichert? Nicht gespeicherte Änderungen gehen verloren, wenn Sie den Designer verlassen.',
    titleConfirmExecution: 'Aktionen durchführen',
    textConfirmExecution: 'Wollen Sie die folgenden Aktionen jetzt durchführen?',
    titleArisModelPath: 'ARIS Modell importieren',
    textArisModelPath: 'Bitte geben Sie den Pfad zum Modell in der ARIS Datenbank ein:',
    columnEdit: {
      titleEditColumn: 'Spalte bearbeiten',
      titleCreateColumn: 'Spalte hinzufügen',
      labelColumnInputType: 'Tastatur-Typ',
      labelColumnType: 'Typ',
      labelColumnName: 'Spaltenname',
      labelColumnWidth: 'Breite in %',
      labelColumnDropDownValues: 'Werte',
    },
  },
  message: {
    loading: 'Laden...',
    saving: 'Speichern...',
    missingConnectionInfo: 'Verbindungsinformationen fehlen oder sind falsch!',
    loginFailed: 'Fehler beim Anmelden. Bitte prüfen Sie die Anmeldedaten!',
    newName: 'Neuer Name:',
    confirmDelete: 'Wirklich löschen?',
    noAccessRights: 'Sie verfügen nicht über die notwendigen Zugriffsrechte!',
  },
  action: {
    designer: 'Designer',
    accessRights: 'Zugriffsrechte...',
    rights: 'Rechte...',
    delete: 'Löschen',
    cancel: 'Abbrechen',
    openInTab: 'In neuem Tab öffnen',
    download: 'Herunterladen',
    edit: 'Bearbeiten',
    remove: 'Entfernen',
    copy: 'Kopieren',
    reactivate: 'Reaktivieren',
    deactivate: 'Deaktivieren',
    search: 'Suchen...',
    sort: 'Sort',
    deleteTenant: 'Kompletten Mandanten löschen',
    workflow: 'Workflow',
  },
  pagination: {
    pageSize: 'pro Seite',
    pageOf: 'von',
    paginationTo: 'bis',
    paginationAnd: 'und',
    paginationOf: 'von',
    element: 'Element',
    elements: 'Elemente',
  },
  sorting: {
    all: 'alle',
    createdAsc: 'erstellt ▲',
    createdDesc: 'erstellt ▼',
    modifiedAsc: 'geändert ▲',
    modifiedDesc: 'geändert ▼',
    state0: 'neu',
    state1: 'in Bearbeitung',
    state2: 'fertig',
    complete: 'vollständig',
    incomplete: 'unvollständig',
  },
  csv: {
    reportName: 'Report Name',
    lastModified: 'geändert am',
    state: 'Status',
    photos: 'Fotos',
    voices: 'Sprachaufnahmen',
  },
  charts: {
    state: 'Status',
    reportField: 'Feld',
    chartType: 'Typ',
    from: 'Von',
    to: 'bis',
    type_bar: 'Balken',
    type_pie: 'Kuchen',
    type_doughnut: 'Donut',
    cb_true: 'Ja',
    cb_false: 'Nein',
    cb_none: 'Keine Angabe',
  },
  placeholder: {
    email: 'E-Mail',
    password: 'Passwort',
    connectionInfo: 'Verbindungsinformationen',
    time: 'Uhrzeit',
    date: 'Datum',
    username: 'Benutzername',
  },
  detail: {
    general: 'Allgemein',
    name: 'Name',
    created: 'erstellt am',
    creator: 'erstellt von',
    modified: 'geändert am',
    modifier: 'geändert von',
    photos: 'Fotos',
    recordings: 'Sprachaufnahmen',
    recording: 'Aufnahme',
    page: 'Seite',
    values: 'Werte',
    longitude: 'Längengrad',
    latitude: 'Breitengrad',
    comments: 'Kommentare',
    placeholderComment: 'Schreibe neuen Kommentar...',
    saveComment: 'kommentieren',
    alteredContinuation: 'hat den Bericht verändert',
    createdContinuation: 'hat den Bericht erstellt',
    commentModified: 'geändert',
    commentDeleted: 'gelöscht',
    stateChangeBefore: 'hat den Status des Berichts von',
    stateChangeTo: 'auf',
    stateChangeAfter: 'geändert',
    report: 'Bericht',
    reports: 'Berichte',
    commentAmount: 'Kommentar',
    commentsAmount: 'Kommentare',
    state0: 'neu',
    state1: 'in Bearbeitung',
    state2: 'fertig',
    selfDelete: 'Kommentar gelöscht',
    otherDelete: 'Kommentar gelöscht durch',
    editComment: 'Speichern',
  },
  editor: {
    undo: 'Undo',
    redo: 'Redo',
    save: 'Speichern',
    tablet: 'Tablet',
    phone: 'Phone',
    aiThinking: 'Die KI denkt nach...',
    language: 'Sprache',
    fallbackLanguage: 'Standard',
    unnamedForm: 'Unbenanntes Formular',
    page: 'Seite',
    hintUseDragAndDrop: 'Bitte ziehen Sie das gewünschte Bild per Drag & Drop auf das Formular.',
    placeholderEnterText: 'Text eingeben...',
    placeholderPleaseChoose: 'Bitte wählen...',
    placeholderTapHere: 'Hier tippen...',
    inspector: {
      general: 'Allgemein',
      phoneOnly: 'Nur Smartphone-Formular',
      name: 'Name',
      view: 'Ansicht',
      gridSize: 'Rastergröße',
      grid: 'Raster',
      auxLines: 'Hilfslinien',
      allPages: 'Alle Seiten',
      inspector: 'Inspektor',
      type: 'Typ',
      title: 'Titel',
      titles: 'Titel',
      titleFallback: 'Standard-Titel',
      languages: 'Sprachen',
      fallbackLanguage: 'Standard',
      isTitleField: 'Ist Titelfeld',
      isMandatory: 'Ist Pflichtfeld',
      function: 'Funktion',
      position: 'Position / Größe',
      posX: 'X-Position',
      posY: 'Y-Position',
      width: 'Breite',
      height: 'Höhe',
      titleWidth: 'Titel-Breite',
      dataBinding: 'Data binding',
      displayCondition: 'Anzeigebedingung',
      field: 'Abhängig von Feld',
      hasToBe: 'Wert muss sein',
      font: 'Schrift',
      fontSize: 'Schriftgröße',
      fontColor: 'Schriftfarbe',
      value: 'Wert',
      values: 'Werte',
      checkboxGroup: 'Checkbox-Gruppe',
      checkboxColor: 'Farbe im Diagramm (HEX Code)',
      date: 'Datum',
      time: 'Zeit',
      repetition: 'Wiedervorlage',
      days: 'Tage',
      datePlaceholder: 'Datum (Platzhalter)',
      timePlaceholder: 'Zeit (Platzhalter)',
      restrictDevice: 'Anzeigen auf',
      allDevices: 'alle Gerätetypen',
      onlyLarge: 'Nur Tablets',
      onlySmall: 'Nur Smartphones',
      hideInput: 'Eingaben verbergen',
      numberInput: 'Nur Zahlen',
      textInput: 'Buchstaben und Zahlen',
      keyboardType: 'Tastatur-Typ',
      action: 'Action',
      columns: 'Spalten',
      hints: {
        name: 'Der Name des Formulars.',
        phoneOnly: 'Dieser Schalter legt fest, dass kein spezielles Formular für Tablets angelegt wird. Auf Tablets wird dann auch das Phone-Formular angezeigt.',
        languages: 'Hier wird festgelegt, aus welchen Anzeigesprachen der Benutzer in der App beim Ausfüllen des Formulars wählen kann.',
        fallback: 'Diese Sprache wird verwendet, wenn für die aktuell gewählte Sprache kein eigener Text hinterlegt ist.',
        gridSize: 'Abstände der Rasterpunkte in Pixeln',
        grid: 'Raster verwenden (anzeigen und einrasten)',
        auxLines: 'Hilfslinie verwenden (anzeigen und einrasten)',
        type: 'Der Feldtyp des aktuell gewählten Felds.',
        title: 'Die im Formular angezeigte Bezeichnung, ggf. in mehreren Sprachen',
        isTitleField: 'Aus allen als Titelfeld markierten Feldern wird automatisch der Name des erfassten Berichts generiert (z.B. Vorname, Nachname, Firma).',
        isMandatory: 'Ist das Feld ein Pflichtfeld? Die Eingabe von Werten wird nicht erzwungen, der Bericht wird auch ohne gefüllte Pflichtfelder in das Portal übertragen, aber in der Liste der Berichte wird eine Warnung angezeigt.',
        page: 'Bestimmt, ob das Feld im Tablet-Modus nur auf einer bestimmten Seite oder auf allen Seiten zu sehen ist.',
        posX: 'Abstand vom linken Formularrand in Pixeln',
        posY: 'Abstand vom oberen Formularrand in Pixeln',
        width: 'Breite in Pixeln',
        height: 'Höhe in Pixeln',
        titleWidth: 'Prozentuale Breite des Titels in Verhältnis zur Gesamtbreite.',
        field: 'Soll das Feld immer oder in Abhängigkeit von der Wahl in einem anderen Feld (einer Auswahlliste oder einem Ankreuzfeld) erfolgen?',
        hasToBe: 'Falls die Anzeige abhängig von einem Feld ist, welchen Wert muss dieses Feld annehmen, damit die Anzeige erfolgt?',
        restrictDevice: 'Soll das Feldes auf allen Geräten oder nur auf Tablets bzw. Smartphones angezeigt werden?',
        font: 'Schriftart des Titels',
        fontSize: 'Schriftgröße des Titels',
        fontColor: 'Schriftfarbe des Titels',
        valueFont: 'Schriftart des Wertes',
        valueFontSize: 'Schriftgröße des Wertes',
        valueFontColor: 'Schriftfarbe des Wertes',
        keyboardType: 'Bei der Eingabe zu verwendender Tastatur-Typ: Buchstaben und Zahlen oder nur Zahlen.',
        hideInput: 'Sensible Inhalte wie Passwörter etc. können bei der Eingabe maskiert werden.',
        action: 'Festlegen der Aktion, die beim Antippen des Aktions erfolgen soll.',
        values: 'Hier kann die Liste der Werte, welche zur Auswahl stehen definiert werden. Es können Werte hinzugefügt, entfernt sowie in ihrer Reihenfolge geändert werden.',
        checkboxGroup: 'Ankreuzfelder können in Gruppen zusammengefasst werden. Innerhalb der gleichen Gruppe kann jeweils nur ein Feld angekreuzt sein. Feldgruppen können im Chart des Formulars als Balken / Kuchensegment angezeigt werden.',
        checkboxColor: 'Farbe der Gruppe im Diagramm im HEX-Format (z.B. #000000 für Schwarz)',
        date: 'Soll das Eingeben eines Datums angeboten werden?',
        time: 'Soll das Eingeben einer Uhrzeit angeobten werden?',
        repetition: 'Soll ein Rhythmus zum Wiederkehren als eingestellt werden können?',
        days: 'Solle die Liste der Wochentage zur Auswahl gestellt werden?',
        datePlaceholder: 'Platzhaltertext, der angezeigt wird, solange kein Datum eingegeben wurde.',
        timePlaceholder: 'Platzhaltertext, der angezeigt wird, solange keine Uhrzeit eingegeben wurde.',
        columns: 'Hier können Sie die Spalten der Tabelle definieren. Es können Spalten hinzugefügt, entfernt, umsortiert und bearbeitet werden.',
      },
      placeholder: {
        newValue: 'Neuer Wert',
        newColumn: 'Spaltenname',
      },
    },
    types: {
      label: 'Text',
      textfield: 'Textfeld',
      textarea: 'Textbereich',
      checkbox: 'Checkbox',
      dropdown: 'Auswahlliste',
      image: 'Bild',
      action: 'Aktion',
      signature: 'Unterschrift',
      background: 'Hintergrund',
      date: 'Datum',
      reference: 'Referenz',
      list: 'Liste',
      table: 'Tabelle',
      hints: {
        label: 'Ein einzeiliger statischer Text. Schriftart, -größe und farbe werden im Inspektor festgelegt.',
        textfield: 'Ein einfaches, einzeiliges Eingabefeld. Schriftart, -größe und farbe werden im Inspektor festgelegt.',
        textarea: 'Ein mehrzeiliger Eingabebereich. Schriftart, -größe und farbe werden im Inspektor festgelegt.',
        checkbox: 'Ein Ankreuzfeld, optional gruppierbar. Bei Gruppierung kann nur ein Feld angehakt sein.',
        dropdown: 'Eine Auswahlliste mit beliebigen Werten zur Auswahl. Nur eine Auswahl möglich.',
        image: 'Ein statisches Bild. Muss per Drag und Drop auf das Formular gezogen werden.',
        action: 'Ein Aktionsfeld. Die konkrete Aktion wird im Inspektor festgelegt.',
        signature: 'Erfasst Unterschriften oder beliebige Zeichnungen mit Finger oder Stift.',
        background: 'Eine einfache geometrische Formm z.B. zur Hintergrundgestaltung. Form und Farbe werden im Inspektor festgelegt.',
        date: 'Ein flexibles Feld zur Datum- und Uhrzeiteingabe. Unterstützt Datum und Uhrzeit, beides einzeln sowie wiederkehrende Termine nach Interval und Wochentagen.',
        reference: 'Feld zur Festlegung einer Referenz. Referenzen gruppieren Daten zu einer Struktur, z.B. ein Projekt, ein Kunde, etc.',
        list: 'Feld zur Einbindung von einfachen dynamischen Listen. Es können Texte in Zeilen hinzugefügt, entfernt und bearbeitet werden.',
        table: 'Feld zur Einbindung von dynamischen Tabellen. Sie können die Zusammenstellung der Spalten bestimmen und dynamisch Zeilen ergängen, entfernen und bearbeiten.',
      },
    },
    actions: {
      recordVoice: 'Kommentar aufnehmen',
      scanQRCode: 'QR Code scannen',
      assignPhotos: 'Bilder zuordnen',
      takePhoto: 'Bilder aufnehmen',
      remoteProcessing: 'Remote Processing',
      locatePosition: 'Position bestimmen',
      loadList: 'Liste laden',
    },
    repetitionOptions: {
      once: 'einmalig',
      daily: 'täglich',
      weekly: 'wöchentlich',
      every14days: '14-tägig',
      monthly: 'monatlich',
      every4weeks: 'alle 4 Wochen',
      quarterly: 'quartalsweise',
      semiYearly: 'halbjährlich',
      yearly: 'jährlich',
    },
    shapes: {
      rectangle: 'Rechteck',
      ellipsis: 'Ellipse',
      line: 'Linie',
    },
    toolbar: {
      addField: 'Feld hinzufügen',
      duplicate: 'Duplizieren',
      delete: 'Löschen',
      addVertical: 'Vertikale Hilfslinie',
      addHorizontal: 'Horizontale Hilfslinie',
      front: 'Nach vorne',
      back: 'Nach hinten',
      cloneTemplate: 'Neues Formular auf Basis der Vorlage',
    },
  },
  error: {
    'code_-1': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    code_901: 'Das Maximum an angemeldeten Geräten wurde erreicht! Bitte kontaktieren Sie Ihren Administrator.',
    code_902: 'Sie haben keine Berechtigung, sich anzumelden! Bitte kontaktieren Sie Ihren Administrator.',
    code_903: 'Ihr Abonnement ist abgelaufen! Sie können keine Daten und Berichte mehr hochladen.',
    code_907: 'Ihr Mandant hat das Speicherplatzlimit erreicht.',
    code_909: 'Der Code stimmt nicht überein.',
    code_920: 'Sie können den letzen Administrator nicht löschen!',
    upload: 'Das Hochladen mindestens einer Datei ist fehlgeschlagen!',
    no_upload_to_root: 'Dateien können nur in Ordner, nicht aber in das Hauptverzeichnis hochgeladen werden.',
    wrongTenantName: 'Die Mandanten ID die Sie eingegeben haben entspricht nicht der ID Ihres Mandanten. Bitte versuchen Sie es noch einmal.',
    requestingNewEmailFailed: 'Das Beantragen einer neuen E-Mail-Adresse ist fehlgeschlagen!',
    general: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  },
  validationError: {
    firstNameEmpty: 'Bitte geben Sie einen Vornamen ein',
    lastNameEmpty: 'Bitte geben Sie einen Nachnamen ein',
    cityEmpty: 'Bitte geben Sie eine Stadt ein',
    streetEmpty: 'Bitte geben Sie eine Straße und Hausnummer',
    zipCodeEmpty: 'Bitte geben Sie eine PLZ ein',
    emailEmpty: 'Bitte geben Sie eine E-Mail-Adresse ein',
    usernameNotAvailable: 'Dieser Nutzername wird bereits verwendet. Bitte verwenden Sie einen andere.',
    emailNotAvailable: 'Diese E-Mail-Adress wird bereits verwendet. Bitte verwenden Sie eine andere.',
    errorEmptyOrInsufficientPassword: 'Bitte geben Sie ein Passwort ein. Regeln: Min. 8 Zeichen, min. ein Groß- und ein Kleinbuchstabe, eine Ziffer und ein Sonderzeichen.',
    errorPasswordsDontMatch: 'Die Passwörter stimmen nicht überein.',
    errorCaptcha: 'Bitte tippen Sie den angezeigten Code ab.',
    errorTermsNotAccepted: 'Sie müssen die genannten Bedingungen akzeptieren, um die Collada Plattform nutzen zu können!',
    invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    errorTenantNameEmpty: 'Bitte geben Sie einen Mandantenamen ein (min. 5 Zeichen).',
    errorAdminNameTooShort: 'Bitte geben Sie einen Nutzernamen für den Admin ein (min. 3 Zeichen).',
    nameEmpty: 'Bitte geben Sie einen Namen ein.',
    usernameTooShort: 'Bitte geben Sie einen Nutzernamen ein (min. 5 Zeichen).',
    columnNameEmpty: 'Bitte geben Sie einen Namen ein.',
  },
  invoicing: {
    titleData: 'Rechnungsdaten',
    taxId: 'USt-ID',
    btnSendNewLink: 'Neuen Bestätiguns-Link schicken',
    btnAddAccoutHolder: 'Rechnungsdaten ergänzen',
    titleLastInvoice: 'Letzte Rechnung',
    tenant: 'Mandant',
    subscriptionType: 'Aktuelles Abonnement',
    subscriptionPeriodStart: 'Startdatum des Abonnements',
    subscriptionPeriodEnd: 'Enddatum des Abonnements',
    running: 'aktiv',
    cancelled: 'wird nicht erneuert.',
    date: 'Datum',
    nr: 'Rechnungsnr.',
    period: 'Period',
    product: 'Produkt',
    amount: 'Amount',
    status: 'Status',
    transactionId: 'Transaktions-ID',
    cancelSubscription: 'Kündigen',
    reactivate: 'Reaktivieren',
    edit: 'Bearbeiten...',
    showPDF: 'PDF anzeigen',
    titleCancelSubscription: 'Kündigen',
    textCancelSubscription: 'Wollen Sie Ihr Abonnement wirklich beenden? Sie werden nach Ablauf des aktuellen Rechnungszeitraums weiter auf das Portal und Ihre Daten zugreifen können, '
      + 'aber keine neuen Daten über die Collada-App mehr erfassen können. Fortfahren?',
    infoMailSent: 'Eine E-Mail mit einem Bestätigungslink wurde an die angegebene Adresse gesendet. Bitte bestätigen Sie Ihre E-Mail-Adresse durch öffnen des Links.',
    modalTitleAddAccountHolder: 'Rechnungsdaten erfassen',
    modalTitleEditAccountHolder: 'Rechnungsdaten bearbeiten',
    btnRequestEmailUpdate: 'Anfrage senden',
    btnCancelEmailUpdate: 'Abbrechen',
    email_unconfirmed_hint: 'Bitte bestätigen Sie Ihre E-Mail-Adresse indem Sie den Link in der Bestätigungsmail öffnen. Im Anschluss können Sie dann ein Abonnement auswählen. '
      + 'Falls Sie keine Mail erhalten haben, prüfen Sie bitte Ihren Spam-Ordner oder fordern Sie eine erneute Mail an (siehe unten).',
    complete_accountholder: 'Bitte vervollständigen Sie zunächst Ihre Rechnungsdaten und bestätigen Sie danach Ihre E-Mail-Adresse. Im Anschluss können Sie dann ein Abonnement auswählen.',
    endOfCurrentPeriod: 'Ende des aktuellen Abrechnungszeitraums',
    nextProductId: 'Gebucht ab nächstem Abrechnungszeitraum',
    history: 'Rechungshistorie',
    subscriptions: 'Abonnements',
    btnSubscribe: 'Abonnieren',
    titleConfirmDialog: 'Bestellung bestätigen',
    textConfirmDialog: 'Mit dem Bestätigen diesen Dialogs bestellen Sie verbindlich und zahlungspflichtig das folgende Abonnement für die Collada-Lösung:',
    hintEffectiveImmediately: 'Das Abonnement wird mit nach der Zahlung sofort mit den genannten Leistungen aktiviert.',
    hintEffectiveNextPeriod: 'Das Abonnement mit den genannten Leistungen wird im Anschluss an den laufenden Abrechnungszeitraum aktiv.',
    // externalPaymentProviderHint: 'Die Zahlung wird über unseren Zahlungsdienstleister <a target="_new" href="https://www.mollie.com">mollie</a> abgewickelt. Ihre Zahlungsdaten werden bei '
    //  + 'mollie gespeichert und verarbeitet. Bitte informieren Sie sich unter <a target="_new" href="https://www.mollie.com/de/privacy">https://www.mollie.com/de/privacy</a> über die Datenschutzerklärung von mollie.',
    externalPaymentProviderHint: 'Jeweils zu Beginn eines Abrechnungszeitraunms wird Ihnen eine Rechnung per E-Mail an die angegebe Adresse zugeschickt. '
    + 'Bitte überweisen Sie den Betrag innerhalb von 14 Tagen nach Erhalt der Rechnung wie auf der Rechnung angegeben.',
    invoice_status_OPEN: 'offen',
    invoice_status_PAID: 'bezahlt',
    hint_net_prices: 'Preise zzgl. 19% USt.',
  },
  workflow: {
    save: 'Speichern',
    load: 'Laden',
    execute: 'Ausführen',
    node: {
      FieldChooser: 'Feld',
      GetFieldValue: 'Wert lesen',
      GetFieldValueCustom: 'Wert lesen',
      SetFieldValue: 'Wert setzen',
      SetFieldValueCustom: 'Wert setzen',
      GetCreationDate: 'Erstellt am',
      GetModificationDate: 'Geändert am',
      GetStatus: 'Status holen',
      SetStatus: 'Status setzen',
      StatusConstant: 'Status',
      SendMail: 'E-Mail senden',
    },
    nodeChooser: {
      FieldChooser: 'Feld wählen',
      GetFieldValue: 'Wert lesen',
      GetFieldValueCustom: 'Wert lesen (eignes Feld)',
      SetFieldValue: 'Wert setzen',
      SetFieldValueCustom: 'Wert setzen (eignes Feld)',
      GetCreationDate: 'Erstellt am',
      GetModificationDate: 'Geändert am',
      GetStatus: 'Status holen',
      SetStatus: 'Status setzen',
      StatusConstant: 'Status wählen',
      SendMail: 'E-Mail senden',
    },
  },
  mailsettings: {
    title: 'Mailserver-Einstellungen',
    labelHost: 'Server',
    labelPort: 'Port',
    labelUsername: 'Benutzername',
    labelPassword: 'Passwort',
    labelFromAddress: 'Absendername',
    securityHint: 'Ihre Mail-Zugangsdaten werden mit Ihrem Mandantenpasswort gespeichert. Weder das Collada-Team noch jemand Anderes '
      + 'als die Administratoren in Ihrem Mandaten können die Daten sehen.',
    error: {
      fromAddress: 'Bitte geben Sie einen Absender ein',
      username: 'Bitte geben Sie einen Benutzernamen ein',
      port: 'Bitte geben Sie einen Port ein',
      host: 'Bitte geben Sie einen Hostnamen ein',
      password: 'Bitte geben Sie ein Passwort ein',
    },
  },
  legal: {
    imprint: 'Impressum',
    terms: 'Nutzungsbedingungen',
    privacy: 'Datenschutzerklärung',
    licenses: 'Lizenzen',
  },
};
